@mixin button($bg) {

    background-color: $bg;
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);

    border: 1px solid darken(desaturate($bg, 20%), 30%);
    color: darken($bg, 50%);

    background-image: linear-gradient(to bottom, lighten($bg, 5%), $bg);
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: .25rem;

    &:hover {
        background-image: linear-gradient(to bottom, lighten($bg, 10%), lighten($bg, 5%));
        box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.5);
    }
    &:active {
        background-image: none;
        background-image: linear-gradient(to bottom, lighten($bg, 5%), $bg);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
    }

}