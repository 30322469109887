/*
*
* Begin helper classes
*
*/
* {
    font-size: inherit;
}
*.invisible {
    display: none !important;
}
*.disabled {
    pointer-events: none;
}
/*
*
* End helper classes
*
*/


/*
*
* Begin core components
*
*/

ap-logo {
    position: absolute;
    width: 30rem;
    left: 50%;
    margin-left: -15rem;
    top: 50%;
    margin-top: -7rem;
    z-index: 1;
    opacity: .025;
}

ap-application {

    // width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    flex: 1 1 0;
    display: flex;
    overflow: hidden;
    background-color: $app-background-color;
    color: #ffffff;
    font-size: inherit;

}

ap-desktop {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    justify-items: stretch;
    overflow: hidden;
    background-color: $app-background-color;
    color: #ffffff;
    font-size: inherit;

    & > ap-window-container {
        flex: 1 1 auto;
        overflow: hidden;
    }

    & > ap-footer {

        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-start;
        transition: opacity .2s;

        background-color: $app-light-background-color;
        border-top: 1px solid $app-light-border-color;

        &.disabled {
            opacity: .5;
        }

        & > ap-start-menu {
            flex: 0 0 auto;
        }

        & > ap-task-bar {
            flex: 1 1 auto;
        }

        & > *[is='ap-image-icon'] {

            background-color: rgba(0, 0, 0, 0);
            width: 4.5rem;
            height: 4.5rem;
            border: .5rem solid rgba(0, 0, 0, 0);
    
            &:hover {
                background-color: rgba(67, 76, 81, .25);
            }
    
            &:active {
                background-color: rgba(67, 76, 81, .5);
            }
        }

    }
}

ap-start-menu {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    & > *[is='ap-image-icon'] {
        
        width: 4.5rem;
        height: 4.5rem;
        //border: .5rem solid rgba(0, 0, 0, 0);

        &:hover {
            background-color: rgba(67, 76, 81, .25);
        }

        &:active {
            background-color: rgba(67, 76, 81, .5);
        }
    }

    & > ap-menu {
        
        position: absolute;
        width: 14rem;
        z-index: 999999999999;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);

    }

}

ap-task-bar {

    flex: 0 0 auto;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;

    background-color: #4e4e50;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.01);
    padding: .25rem;
    margin: .25rem;
    border-radius: 3px;

    background-color: $app-dark-background-color;
    border: 1px solid $app-dark-border-color;
    
    & > *[is='ap-icon-button'] {

        flex: 0 0 auto;
        color: #c1c7cb;
        margin: 0 1px 0 0;
        justify-content: center;
        align-items: center;
        transition: background-color .2s, border .2s, color .2s;
        padding: .5rem;
        padding-right: 1rem;
        font-weight: 600;
        border-radius: .2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        cursor: default;
        background-image: none;
        box-shadow: none;
        background-color: $app-dark-background-color;
        border: 1px solid $app-dark-background-color;
        text-shadow: none;

        &:hover {
            background-image: none;
            box-shadow: none;
            background-color: rgba($app-light-background-color, .5);
            border: 1px solid rgba($app-light-border-color, .5);
        }

        &:active {
            background-image: none;
            box-shadow: none;
            background-color: $app-light-background-color;
            border: 1px solid $app-light-border-color;
        }

        &.selected {
            background-image: none;
            box-shadow: none;
            background-color: $app-light-background-color;
            border: 1px solid $app-light-border-color;
            color: #eeeeee;
            background-image: linear-gradient(to bottom, lighten($app-light-background-color, 10%), $app-light-background-color);
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        }

        & > * {
            pointer-events: none;
        }

        & > *[is='ap-image-icon'] {
            flex: 0 0 auto;
            max-width: 2rem;
            max-height: 2rem;
            margin-right: .5rem;
            display: none;

            &[src] {
                display: block;
            }
        }

        & > ap-text {
            flex: 1 1 0;
            max-width: 14rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

ap-text {
    display: block;
}

ap-label {
    color: #c1c7cb;
    font-size: .9rem;
    text-shadow: none;
    text-transform: lowercase;
}

ap-container {
    display: flex;
}

*[is='ap-form'] {

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > ap-label {
        margin-bottom: .25rem;
    }

    & > *[is='ap-text-input'] {
        margin-bottom: .5rem;
    }

    & > *[is='ap-password-input'] {
        margin-bottom: .5rem;
    }

    & > *[is='ap-select'] {
        margin-bottom: .5rem;
    }

    & > *[is='ap-button'], & > *[is='ap-icon-button'] {
        margin-top: .5rem;
    }

    & > ap-qr-code-svg {
        margin-bottom: .5rem;
    }

    & > ap-text.error{
        color: $app-error-font-color
    }

    // &:invalid {
    //     & > *[is='ap-button'] {
    //         opacity: .65;
    //         pointer-events: none;
    //         text-shadow: none;
    //         box-shadow: none;
    //     }
    // }

    & > ap-text.title {
        align-self: center;
        margin-bottom: .5rem;
    }

}

ap-checkbox {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > input[type='checkbox'] {
        margin: 0;
    }

    & > ap-text {
        margin-left: .25rem;
        color: #eeeeee;
    }
}

*[is='ap-button'], *[is='ap-icon-button'] {

    // flex: 1 1 auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    padding: .5rem;
    cursor: default;
    font-weight: 600;
    font-size: 1rem;

    transition: opacity .2s;

    @include button($app-default-button-background-color);

    &.red {
        @include button($app-red-button-background-color);
    }
    &.green {
        @include button($app-green-button-background-color);
    }
    &.blue {
        @include button($app-blue-button-background-color);
    }

    &.disabled {
        opacity: .65;
        pointer-events: none;
        text-shadow: none;
        box-shadow: none;
    }

}

*[is='ap-text-input'], *[is='ap-password-input'], *[is='ap-select'] {
    font-size: 1rem;
    border-radius: 2px;
    color: #eeeeee;
    padding: .5rem;
    user-select: contain;
    transition: background-color .2s, border-color .2s;
    outline: none;
    min-width: 0;

    background-color: $app-dark-background-color;
    border: 1px solid $app-dark-border-color;

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

    // &.alert {
        //background-color: #8a7f4e;
    // }

    // &.error {
        //background-color: #8a4e4e;
    // }

    &:placeholder-shown {
        text-shadow: none;
    }
    &::placeholder {
        color: $app-light-background-color;
        font-style: italic;
        text-shadow: none;
    }

    &.disabled {
        opacity: .65;
    }

    &:invalid {
        background-color: adjust-color($app-dark-background-color, $red: 63);
        border-color: adjust-color($app-dark-border-color, $red: 63);
    }

    &[type='number'] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
        -moz-appearance:textfield; /* Firefox */
    }
}

ap-button-bar {
    
    flex: 1 1 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    border: 1px solid #353e43;
    background-color: #434c51;
    padding: .25rem;
    border-radius: 3px;

    & > *[is='ap-icon-button'] {

        flex: 1 1 0;
        color: #c1c7cb;
        background-color: #434c51;
        border: 1px solid #434c51;
        margin: 0 1px 0 0;
        justify-content: center;
        transition: background-color .2s, border .2s, color .2s;
        padding: .25rem .5rem;
        box-shadow: none;
        font-weight: 400;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        &:active {
            background-color: rgba(93, 104, 109, .5);
            border: 1px solid #353e43;
        }

        &.selected {
            background-color: #5d686d;
            border: 1px solid #353e43;
            color: #eeeeee;
        }

        & > * {
            pointer-events: none;
        }

        & > *[is='ap-image-icon'] {
            flex: 0 0 auto;
            max-width: 2rem;
            max-height: 2rem;
            margin-right: .5rem;
            display: none;

            &[src] {
                display: block;
            }
        }

        & > ap-text {
            flex: 1 1 0;
        }



    }
    & > .button:last-child {
        margin-right: 0;
    }
}

ap-menu-bar {
    
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    background-color: #5d686d;
    box-shadow: none;
    transition: opacity .25s;

    &.hidden {
        pointer-events: none;
    }

    & > ap-button-bar {

        flex: 0 0 auto;
        flex-direction: row;
        border: none;
        padding: 0;
        background-color: #5d686d;
        border-radius: 0;

        & > *[is='ap-icon-button'] {
            
            flex: 0 0 auto;
            padding: .5rem 1rem;
            background-color: #5d686d;
            border: none;
            font-size: 1rem;
            border: none;
            cursor: pointer;
            box-shadow: none;

            &.selected {
                background-color: #353e43;
                border: none;
            }

            &:hover {
                background-color: #434c51;
                border: none;
            }
        }
    }

    & > ap-menu {
        
        position: absolute;
        top: 2.25rem;
        width: 12rem;
        z-index: 999999999999;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);

    }
    
}

ap-tab-bar {
    
    flex: 1 1 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    border: 1px solid $app-dark-border-color;
    background-color: $app-dark-background-color;
    padding: .25rem;
    border-radius: .25rem;
    padding-bottom: 0;

    & > *[is='ap-icon-button'] {

        flex: 1 1 0;
        color: #c1c7cb;
        margin: 0 1px 0 0;
        justify-content: center;
        transition: background-color .2s, border .2s, color .2s;
        padding: .25rem .5rem;
        box-shadow: none;
        font-weight: 400;
        border-radius: .2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding-bottom: .5rem;
        margin-bottom: -1px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        cursor: default;
        transition: none;

        background-color: $app-dark-background-color;
        border: 1px solid $app-dark-background-color;
        border-bottom: 1px solid $app-dark-border-color;
        background-image: none;
        text-shadow: none;
        box-shadow: none;

        &:hover {
            background-image: none;
            text-shadow: none;
            box-shadow: none;
            background-color: rgba($app-light-background-color, .5);
            border: 1px solid rgba($app-light-border-color, .5);
            border-bottom-color: rgba($app-light-background-color, .5);
        }

        &:active {
            
            border-bottom: 1px solid #5d686d;
            background-image: none;
            text-shadow: none;
            box-shadow: none;

            background-color: $app-light-background-color;
            border: 1px solid $app-light-border-color;
            border-bottom-color: $app-light-background-color;
        }

        &.selected {
            background-image: none;
            color: #eeeeee;

            background-color: $app-light-background-color;
            border: 1px solid $app-light-border-color;
            border-bottom-color: $app-light-background-color;
            background-image: linear-gradient(to bottom, lighten($app-light-background-color, 10%), $app-light-background-color);
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        }

        & > * {
            pointer-events: none;
        }

        & > *[is='ap-image-icon'] {
            flex: 0 0 auto;
            max-width: 2rem;
            max-height: 2rem;
            margin-right: .5rem;
            display: none;

            &[src] {
                display: block;
            }
        }

        & > ap-text {
            flex: 1 1 0;
        }



    }
    & > .button:last-child {
        margin-right: 0;
    }
}

ap-tab-view {

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: stretch;

    & > ap-tab-bar {

        flex: 0 0 auto;
        z-index: 1;

        & > *[is='ap-icon-button'] {
            z-index: 2;
        }
    }

    & > ap-container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: stretch;
        padding-top: .5rem;

        & > * {
            flex: 1 1 0;
        }
    }

}

ap-menu, ap-context-menu {
    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: absolute;

    & > ap-menu-item {

        //height: 2.25rem;
        padding: .75rem;
        background-color: $app-light-background-color;
        // cursor: pointer;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        text-shadow: 0 1px 2px rgba(0, 0, 0, .2);

        & > * {
            pointer-events: none;
        }

        &:hover {
            background-color: darken($app-light-background-color, 5%);
        }

        &:active {
            background-color: darken($app-light-background-color, 10%);
        }

        & > *[is='ap-image-icon'] {
            flex: 0 0 auto;
            max-width: 2.5rem;
            max-height: 2.5rem;
            margin-right: .75rem;
            display: none;

            &[src] {
                display: block;
            }
        }

        & > ap-text {
            flex: 1 1 0;
        }

    }

}

ap-context-menu {
    width: 12rem;
    z-index: 999999999999;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
    background-color: #5d686d;
}

ap-tree {

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    overflow: auto;

    border: 1px solid $app-dark-border-color;
    background-color: $app-dark-background-color;

    & ap-tree-node {
        
        pointer-events: all;
        flex: 0 0 auto;
        cursor: default;
        background-color: rgba(0, 0, 0, 0);
        min-height: min-content;

        & > ap-tree-node-label {

            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color .2s;

            &:hover {
                background-color: darken($app-dark-background-color, 5%);
            }
            &:active {
                background-color: darken($app-dark-background-color, 10%);
            }

            & > ap-font-awesome-icon {

                flex: 0 0 auto;
                padding: .5rem;
                color: #000000;

                &.invisible {
                    display: block !important;
                    opacity: 0;
                    pointer-events: none;
                }
            }

            & > ap-text {
                flex: 1 1 auto;
                color: #ffffff;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .5);
            }

        }

        & > ap-tree-node-nodes {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            padding-left: 1rem;
            align-items: stretch;
            justify-content: flex-start;
        }

        &.selected {

            & > ap-tree-node-label {

                background-color: darken($app-dark-background-color, 10%);

                & > ap-text {
                    font-weight: bold;
                }

                // &:hover {
                //     background-color: lighten($app-dark-background-color, 10%);
                // }
                
                // &:active {
                //     background-color: lighten($app-dark-background-color, 5%);
                // }

            }
        }
    }

}

ap-panel-grid {
    z-index: 2;

    display: grid;
    position: relative;

    grid-template-columns: repeat(50, 1fr);
    grid-template-rows: repeat(40, 1fr);

    margin: 1px;

    grid-auto-flow: row;

    overflow: hidden;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

    & > .ghost {
        background-color: #191c23;
        opacity: .8;
        mix-blend-mode: color-burn;
    }

    & > ap-panel {

        // margin: var(--margin);

        // &.hidden {
            // display: inherit !important;
            // opacity: var(--opacity);
            // pointer-events: none;
            // transform: var(--transform);
        // }

        &.maximized {
            grid-area: 1 / 1 / 41 / 51 !important;
            z-index: 99999;
        }

        // &.maximizable {
        // }

    }
}

ap-panel {

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
    min-width: 10rem;
    min-height: 10rem;
    transition: opacity .25s, box-shadow .25s;
    transform: none;
    position: relative;
    overflow: hidden;
    background-color: #434c51;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.01);
    border: 1px solid #2d363b;
    border-radius: .25rem;
    position: relative;

    &.transforming {
        position: absolute;
        pointer-events: none;
        opacity: .75;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.5);
    }

    &.non-draggable {
        & > ap-panel-header {
            cursor: default;
            pointer-events: none;
        }
    }

    &.non-resizeable {
        & > ap-panel-resize-targets {
            & > b {
                pointer-events: none;
                cursor: default;
            }
        }
    }

    & > ap-panel-resize-targets {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        & > b {
            position: absolute;
            pointer-events: all; 
        }
        & > b:nth-of-type(1) {
            top: 0;
            left: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: nw-resize;
        }
        & > b:nth-of-type(2) {
            top: 0;
            left: .5rem;
            right: .5rem;
            height: .5rem;
    
            cursor: n-resize;
        }
        & > b:nth-of-type(3) {
            top: 0;
            right: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: ne-resize;
        }
        & > b:nth-of-type(4) {
            top: .5rem;
            left: 0;
            width: .5rem;
            bottom: .5rem;
    
            cursor: w-resize;
        }
        & > b:nth-of-type(5) {
            top: .5rem;
            right: 0;
            width: .5rem;
            bottom: .5rem;
    
            cursor: e-resize;
        }
    
        & > b:nth-of-type(6) {
            bottom: 0;
            left: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: sw-resize;
        }
        & > b:nth-of-type(7) {
            bottom: 0;
            left: .5rem;
            right: .5rem;
            height: .5rem;
    
            cursor: s-resize;
        }
        & > b:nth-of-type(8) {
            bottom: 0;
            right: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: se-resize;
        }
    }

    & > ap-panel-header {

        flex: 0 0 2.25rem;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        background-color: #434c51;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: .5rem;
        pointer-events: all;

        & > .title {
            flex: 1 1 auto;
            font-size: 1rem;
            font-weight: 200;
            cursor: pointer;
            text-align: left;
            margin: 0;
            padding: 0 0 0 .25rem;
            color: #ffffff;
            pointer-events: none;
        }

        & > .settings {
            flex: 0 0 auto;
            font-size: 1.25rem;
            padding: 0 .25rem 0 .75rem;
            color: #ffffff;
            cursor: pointer;
            margin: 0;
            pointer-events: all;
        }
    }

    & > ap-panel-contents-container {
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        background-color: #5e686d;
        border: none;
        margin: 0 .25rem .25rem .25rem;
        padding: .5rem;
        border: 1px solid #353e43;
    }

}

ap-window-container {

    display: block;

    z-index: 2;

    position: relative;

    margin: 1px;

    overflow: hidden;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */


    & > ap-window {

        // margin: var(--margin);
        position: absolute;
        // transition: width .2s, height .2s;
        transform-origin: bottom;

        // &.hidden {
            // display: inherit !important;
            // opacity: var(--opacity);
            // pointer-events: none;
            // transform: var(--transform);
        // }

        &.minimized {
            // transform-origin: 50% 100%;
            transform: scale(.5, .5) translateY(10vh);
            // transform: rotateX(90deg);
            opacity: 0;
            pointer-events: none;
        }

        &.maximized {
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
        }

        // &.maximizable {
        // }

        &.transforming.dragging.outbound {
            transition: top .2s, left .2s, width .2s, height .2s;
            transform-origin: center center;
        }

        &.transforming {
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.5);
        }

        &:not(.focused) {
    
            & > ap-window-header {

                background-image: none;
        
                & > .title {
                    opacity: .5;
                    text-shadow: none;
                }
        
                & > ap-font-awesome-icon {
                    opacity: .5;
                }
            }
    
        }

    }
}

ap-window {

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
    min-width: 10rem;
    min-height: 10rem;
    transition: opacity .2s, box-shadow .2s, transform .2s;
    transform: none;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.01);
    border-radius: .25rem;
    position: relative;

    background-color: $app-dark-background-color;
    border: 1px solid $app-dark-border-color;

    &.focused {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    }

    &.transforming {
        position: absolute;
        pointer-events: none;
        opacity: .75;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.5);
    }

    &.non-draggable {
        & > ap-panel-header {
            cursor: default;
            pointer-events: none;
        }
    }

    &.non-resizeable {
        & > ap-panel-resize-targets {
            & > b {
                pointer-events: none;
                cursor: default;
            }
        }
    }

    & > ap-window-resize-targets {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        & > b {
            position: absolute;
            pointer-events: all; 
        }
        & > b:nth-of-type(1) {
            top: 0;
            left: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: nw-resize;
        }
        & > b:nth-of-type(2) {
            top: 0;
            left: .5rem;
            right: .5rem;
            height: .5rem;
    
            cursor: n-resize;
        }
        & > b:nth-of-type(3) {
            top: 0;
            right: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: ne-resize;
        }
        & > b:nth-of-type(4) {
            top: .5rem;
            left: 0;
            width: .5rem;
            bottom: .5rem;
    
            cursor: w-resize;
        }
        & > b:nth-of-type(5) {
            top: .5rem;
            right: 0;
            width: .5rem;
            bottom: .5rem;
    
            cursor: e-resize;
        }
    
        & > b:nth-of-type(6) {
            bottom: 0;
            left: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: sw-resize;
        }
        & > b:nth-of-type(7) {
            bottom: 0;
            left: .5rem;
            right: .5rem;
            height: .5rem;
    
            cursor: s-resize;
        }
        & > b:nth-of-type(8) {
            bottom: 0;
            right: 0;
            width: .5rem;
            height: .5rem;
    
            cursor: se-resize;
        }
    }

    & > ap-window-header {

        flex: 0 0 0;
        justify-content: space-between;
        flex-direction: row;
        background-color: $app-dark-background-color;
        display: flex;
        padding: .5rem;
        align-items: center;
        pointer-events: all;
        transition: background-image 0.2s;
        background-image: linear-gradient(to bottom, $app-light-background-color, $app-dark-background-color);

        & > .title {
            flex: 1 1 auto;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            text-align: left;
            margin: 0;
            color: #ffffff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            pointer-events: none;
            transition: opacity .2s;
            text-shadow: 0 1px 2px rgba(0, 0, 0, .5);
        }

        & > ap-font-awesome-icon {
            flex: 0 0 auto;
            font-size: 1rem;
            //padding: 0 .25rem 0 .75rem;
            padding: 0 .5rem;
            color: #ffffff;
            cursor: default;
            margin: 0;
            //margin-left: .5rem;
            pointer-events: all;
            transition: opacity .2s;

            &.minimize {
                font-size: .9rem;
            }
            &.maximize {
                font-size: .9rem;
            }
            &.close {
                font-size: 1.1rem;
            }

            &:hover {
                text-shadow: 0 2px 2px rgba(0, 0, 0, .75);
            }

            &:active {
                text-shadow: none;
            }

        }
    }

    & > ap-application {
        flex: 1 1 0;
        display: flex;
        margin: 0 .25rem .25rem .25rem;
        overflow: hidden;
        background-color: $app-light-background-color;
        border: 1px solid $app-light-border-color;
    }

}

ap-scroll-bar {

    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    background-color: #323b3f;

    & > *[is='ap-scroll-bar-button'] {

        flex: 0 0 1.75rem;
        width: 1.75rem;
        height: 1.75rem;
        justify-content: center;
        align-items: center;
        outline: none;
        border: 1px solid #353e43;
        background-color: #50585d;
        box-shadow: inset 0px 0px 0px 1px #636c70;
        color: #283136;
        padding: 1px;

        border: 1px solid $app-dark-border-color;
        background-color: $app-light-background-color;
        box-shadow: inset 0px 0px 0px 1px lighten($app-light-background-color, 10%);

        & > .icon {
            font-size: 1rem;
        }

        &:active {
            color: #c1c7cb;
        }

    }

    & > ap-scroll-bar-track {

        flex: 1 1 auto;
        position: relative;

        background-color: $app-dark-border-color;

        & > *[is='ap-scroll-bar-handle'] {

            position: absolute;

            width: 1.75rem;
            height: 1.75rem;

            border: 1px solid $app-dark-border-color;
            background-color: $app-light-background-color;
            box-shadow: inset 0px 0px 0px 1px lighten($app-light-background-color, 10%);

        }
    }
    
    &.vertical {
        flex-direction: column;

        & > *[is='ap-scroll-bar-button'] {
            &:first-of-type {
                border-top: none;
                border-right: none;
            }
            &:last-of-type {
                border-bottom: none;
                border-right: none;
            }
        }
        & > ap-scroll-bar-track {
            & > *[is='ap-scroll-bar-handle'] {
                border-right: none;
            }
        }
    }
    &.horizontal {
        flex-direction: row;

        & > *[is='ap-scroll-bar-button'] {
            &:first-of-type {
                border-left: none;
                border-bottom: none;
            }
            &:last-of-type {
                border-bottom: none;
                border-right: none;
            }
        }
        & > ap-scroll-bar-track {
            & > *[is='ap-scroll-bar-handle'] {
                border-bottom: none;
            }
        }
    }
}

ap-data-grid {

    overflow: hidden;
    display: grid;
    position: relative;
    grid-template-columns: auto 1.75rem;
    grid-template-rows: 1.75rem auto 1.75rem;
    border: 1px solid $app-dark-border-color;
    background-color: $app-dark-background-color;
    --row-height: #{$data-grid-row-height};
    
    & > ap-data-grid-header {

        grid-column-start: 1;
        grid-column-end: 2;
        
        grid-row-start: 1;
        grid-row-end: 2;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: stretch;
        overflow: hidden;
        z-index: 1;

        pointer-events: none;
        border-top: 1px solid lighten($app-light-background-color, 20%);
        border-bottom: 1px solid $app-dark-border-color;
        background-color: $app-light-background-color;
        background-image: linear-gradient(to bottom, lighten($app-light-background-color, 10%), $app-light-background-color);
        color: #dddddd;

        & > ap-data-grid-header-item {

            display: flex;
            flex: 1 1 0;
            font-size: 1rem;
            background: rgba(0, 0, 0, 0) !important;
            border: none;
            border-right: 1px solid darken($app-light-background-color, 10%);
            white-space: pre;
            margin: 0;
            padding: 0;
            box-shadow: none;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: nowrap;

            background-color: rgba(0, 0, 0, 0);

            &:last-of-type {
                border-right: none;
            }

            & > ap-text {
                flex: 0 0 auto;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            
            & > ap-font-awesome-icon {
                flex: 0 0 auto;
                margin: 0 .5rem;
                font-size: .9rem;
            }

        }
    }

    & > ap-data-grid-rows-container {

        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        overflow: hidden;

        & > ap-data-grid-row {

            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: stretch;
            min-height: $data-grid-row-height;
            line-height: $data-grid-row-height;
            background-color: $app-dark-background-color;

            &:nth-child(even) {
                background-color: $app-dark-background-color;
            }
            &:nth-child(odd) {
                background-color: darken($app-dark-background-color, 5%);
            }

            & > ap-data-grid-row-item {

                flex: 1 1 0;
                width: 0;
                text-align: center;
                text-shadow: none;
                pointer-events: none;
                line-height: 2rem;
                // user-select: none;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }

            &:empty, &.empty {
                pointer-events: none;
            }

            &:not(:empty) {

                // cursor: pointer;

                &[draggable=true] {
                    cursor: grab;
                }

                &:hover {
                    background-color: darken($app-dark-background-color, 10%);
                }

                &.selected {
                    background-color: darken($app-dark-background-color, 15%);
                }

            }

        }

        // & > .data-grid-row {
        //     transition: translateY 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
        //     background: rgba(0, 0, 0, 0);
        // }

    }

    & > ap-scroll-bar.vertical {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    & > ap-scroll-bar.horizontal {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    &.hide-header {
        & > ap-data-grid-header {
            display: none;
        }
        & > ap-data-grid-rows-container {
            grid-row-start: 1;
        }
    }

    &.hide-vertical-scrollbar {
        & > ap-data-grid-header {
            grid-column-end: 3;
        }
        & > ap-data-grid-rows-container {
            grid-column-end: 3;
        }
        & > ap-scroll-bar:nth-of-type(2) {
            grid-column-end: 3;
        }
        & > ap-scroll-bar:nth-of-type(1) {
            display: none;
        }
    }

    &.hide-horizontal-scrollbar {
        & > ap-data-grid-rows-container {
            grid-row-end: 4;
        }
        & > ap-scroll-bar:nth-of-type(1) {
            grid-row-end: 4;
        }
        & > ap-scroll-bar:nth-of-type(2) {
            display: none;
        }
    }

    

    &::after {
        opacity: 0;
        display: none;
    }

    &.dragging-over {

        // border: 1px solid #ff0000;
        & > * {
            pointer-events: none;
        }

        &::after {
            opacity: 1;
            box-shadow: inset 0 0 0 .2rem rgba(255, 255, 255, .5);
            // background-color: rgba(0, 0, 0, .3);
            // mix-blend-mode: color-burn;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 999999;
        }

        

    }

}

ap-qr-code-svg {
    min-width: 10rem;
    min-height: 10rem;

    background-color: $qr-code-svg-background-color;
    color: $qr-code-svg-block-color;
    border: 1px solid $app-light-border-color;
}