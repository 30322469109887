* {
    box-sizing: border-box;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-callout: none;
    -webkit-touch-callout: none;
}

html, body {
    width: 100%;
    height: 100%;

    font-family: Arial, Helvetica, sans-serif;

    font-size: 11px;

    overflow: hidden;
    position: relative;

    margin: 0;
    padding: 0;
}
body {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    //display: flex;
    // overflow: hidden;

}

.hidden {
    display: none !important;
}

input, button {
    outline: none;
    min-width: 0;
}