$app-background-color: #1a1a1d;

$app-light-background-color: #6d6d6f;
$app-light-border-color: #414144;

$app-dark-background-color: #4e4e50;
$app-dark-border-color: #3a3a3c;

$app-default-button-background-color: #a5a5a7;
$app-red-button-background-color: #ca6e6e;
$app-green-button-background-color: #6bb164;
$app-blue-button-background-color: #717fc0;
$app-font-color: #cccccc;
$app-error-font-color: #9b3b3b;

$qr-code-svg-background-color: #cccccc;
$qr-code-svg-block-color: #1a1a1d;

$data-grid-row-height: 2rem;